import {create} from 'zustand'
import {generateRandomString} from "../utils/helpers";

const usePaymentFormStore = create((set) => ({
    useExistingCustomer: false,
    saveCustomerDetails: false,
    splitPayment: false,
    test: false,
    customerUuid: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zipCode: '',
    country: '',
    expiresAt: '',
    supplier: '',
    customerReference: '',

    // products: [{id: generateRandomString(), title: "product 1", details: "comment on product 1", amount: 30, vat: 0, quantity: 1, total: 30}, {id: generateRandomString(), title: "product 2", details: "", amount: 25, vat: 0, quantity: 2, total: 50}],
    products: [{id: generateRandomString(), title: "product 1", details: "", amount: 0, vat: 0, quantity: 1, total: 0}],

    setUseExistingCustomer: (value) => set({useExistingCustomer: value}),
    setSaveCustomerDetails: (value) => set({saveCustomerDetails: value}),
    setSplitPayment: (value) => set({splitPayment: value}),
    setCustomerUuid: (value) => set({customerUuid: value}),
    setTest: (value) => set({test: value}),
    setName: (value) => set({name: value}),
    setEmail: (value) => set({email: value}),
    setPhone: (value) => set({phone: value}),

    setAddress: (value) => set({address: value}),
    setCity: (value) => set({city: value}),
    setZipCode: (value) => set({zipCode: value}),
    setCountry: (value) => set({country: value}),
    setExpiresAt: (value) => set({expiresAt: value}),
    setSupplier: (value) => set({supplier: value}),
    setCustomerReference: (value) => set({customerReference: value}),

    setProducts: (value) => set(() => {
        let newProducts = value.map((product) => ({
            ...product,
            amount: parseFloat(product.amount),
            vat: parseFloat(product.vat),
            quantity: parseFloat(product.quantity),
            total: parseFloat(product.total),
        }));
        return {products: newProducts}
    }),
    fillCustomerDetails: (customer) => set({
        name: customer?.name || '',
        email: customer?.email || '',
        phone: customer?.phone || '',
        address: customer?.address || '',
        city: customer?.city || '',
        zipCode: customer?.zipCode || '',
        country: customer?.country || '',
    }),

    // setUseExistingCustomer: () => set({useExistingCustomer: false}),
    // setSaveCustomerDetails: () => set({saveCustomerDetails: false}),
    // setSplitPayment: () => set({splitPayment: false}),
    // setCustomerUuid: () => set({customerUuid: ''}),
    // setName: () => set({name: ''}),
    // setEmail: () => set({email: ''}),
    // setPhone: () => set({phone: ''}),
    // setProducts: () => set({products: []}),

    resetPaymentFormAction: () => {
        set({
            useExistingCustomer: false,
            saveCustomerDetails: false,
            splitPayment: false,
            customerUuid: '',
            name: '',
            email: '',
            phone: '',
            products: [
                {
                    id: generateRandomString(),
                    title: "product 1",
                    details: "",
                    amount: 0,
                    vat: 0,
                    quantity: 1,
                    total: 0
                }
            ],
            supplier: '',
            customerReference: '',
        })
    }
}))

export default usePaymentFormStore;