import React from 'react';
import {useTranslation} from "react-i18next";
import {formatNumberFloat} from "../../../../utils/helpers";
import StatusBadge from "../../../../components/StatusBadge";
import {PaperAirplaneIcon, EyeIcon, FlagIcon} from "@heroicons/react/24/solid";
import SortIcon from "../../../../components/SortIcon";
import {PAYD_URL} from "../../../../utils/env";
import {Link} from "react-router-dom";

function CompactPaymentList({handleChangeSort, records, sort, setLinkToShow}) {
    const {t} = useTranslation();
    const tableHeader = [
        {column: 'invoice_details', title: t('payment.table.invoice_details'), sortable: false},
        {column: 'customer_details', title: t('payment.table.customer_details'), sortable: false},
        {column: 'transaction_details', title: t('payment.table.transaction_details'), sortable: false},
        {column: 'payment_reference', title: t('payment.table.payment_reference'), sortable: false},
        // {column: 'amount_details', title: t('payment.table.amount_details'), sortable: false},
        {column: 'view', title: t('payment.table.view'), sortable: false, hidden: true},]
    return <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        {tableHeader?.map(item => (<th key={item.column} scope="col"
                                                       className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                            {!item.hidden && <>
                                {item.sortable ? <button onClick={() => handleChangeSort(item.column)} type="button"
                                                         className="group inline-flex">
                                    {item.title}
                                    <SortIcon sort={sort} field={item.column}/>
                                </button> : <span>{item.title}</span>}
                            </>}
                        </th>))}
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {records?.data?.map((record) => (<tr key={record?.paymentUuid} className="hover:bg-gray-50">
                        <td className="whitespace-nowrap align-top py-4 pl-4 pr-3 text-xs text-gray-500 sm:pl-0">
                            <Row title={`ID / Ref`} value={record?.paymentUuid}/>
                            <Row title={`Invoice Amount`}
                                 value={`${formatNumberFloat(record?.amount, ['DA', 'DZD'].includes(record?.currency?.symbol) ? 'fr-DZ' : 'en-US')} ${record?.currency?.symbol || ''}`}/>
                            <Row title={`Created By`} value={record?.user?.email || ''}/>
                            <Row title={`Created Date`} value={record?.createdAt || ''}/>
                            <Row title={`Created From`} value={record?.fromApi ? 'API' : 'Web Dashboard'}/>
                            <Row title={`Supplier`} value={record?.supplier?.supplierName  || ''}/>
                        </td>
                        <td className="whitespace-nowrap align-top px-3 py-4 text-xs text-gray-500">
                            <Row title={`Name`} value={record?.name} valueClassName="max-w-[150px] truncate"/>
                            <Row title={`Email`} value={record?.email}/>
                            <Row title={`Mobile`} value={record?.phone}/>
                            <Row title={`Remarks`} value={record?.remarks}
                                 className="line-clamp-2 text-pretty max-w-[150px]"/>
                        </td>
                        <td className="whitespace-nowrap align-top px-3 py-4 text-xs text-gray-500">
                            <StatusBadge status={record?.status}/>
                            <Row title={`Type`} value={'Payment Link'}/>
                            <Row title={`Live`}
                                 value={<StatusBadge h='h-3' w='w-3' status={!record?.test} boolean={true}/>}/>
                            <Row title={`Anti Fraud`}
                                 value={record?.antiFraud ? <FlagIcon className="h-4 w-4 text-rose-500"/> : ''}/>
                        </td>
                        <td className="whitespace-nowrap align-top px-3 py-4 text-xs text-gray-500">
                            <Row title={`Customer Ref`} value={record?.customerReferenceNumber}/>
                            <Row title={`Payment ID`} value={record?.gatewayReferenceNumber}/>
                            <Row title={`Auth Code`} value={record?.authCode}/>
                            <Row title={`Paid At`} value={record?.paidAt}/>
                            <Row title={`Card`} value={record?.cardNumber}/>
                        </td>
                        {/*<td className="whitespace-nowrap align-top px-3 py-4 text-xs text-gray-500">*/}
                        {/*	<Row title={`Payable Amount`} value={`${formatNumberFloat(record?.amount)} ${record?.currency?.symbol || ''}`}/>*/}
                        {/*	<Row title={`VAT Amount`} value={'1.02 AED'}/>*/}
                        {/*	<Row title={`Receivable Amount`} value={'1,770.56 AED'}/>*/}
                        {/*</td>*/}
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0 flex gap-2 items-center">
                            <div
                                className="text-white bg-gradient-to-br from-red-400 to-orange-400 hover:from-red-300 hover:to-orange-300 select-none cursor-pointer p-1 rounded-md"
                                onClick={() => {
                                    setLinkToShow(`${PAYD_URL}/pay/${record?.paymentUuid}`)
                                }}>
                                <PaperAirplaneIcon className="w-5 h-5"/>
                            </div>
                            <Link to={`/merchant/payments/view/${record?.paymentUuid}`}
                                  className="text-white bg-gradient-to-br from-red-400 to-orange-400 hover:from-red-300 hover:to-orange-300 select-none p-1 rounded-md">
                                <EyeIcon className="w-5 h-5 "/>
                            </Link>
                        </td>
                    </tr>))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>;
}


function Row({title, value, valueClassName}) {
    return <div className="flex items-center gap-1">
        <div className="font-medium">{title}{':'}</div>
        {value ? <div className={valueClassName}>{value}</div> : <div className="opacity-60">N/A</div>}
    </div>
}

export default CompactPaymentList;