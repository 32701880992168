import React, {useMemo, useState} from 'react';
import DashboardLayout from "../../layouts/DashboardLayout";
import {clsx} from "clsx";
import {apollo} from "../../utils/apollo";
import {useQuery} from "react-query";
import Loading from "../../components/Loading";
import {useTranslation} from "react-i18next";
import {GET_MERCHANT_SUPPLIERS_LIST, GET_PAYMENT_SUMMARY_LIST} from "../../utils/graphql";
import Pagination from "../../components/Pagination";
import PageHeader from "../../components/PageHeader";
import SortIcon from "../../components/SortIcon";
import {formatNumberInt, formatNumberFloat} from "../../utils/helpers";
import SelectInput from "../../components/SelectInput";
import useAuthStore from "../../store/auth";

const START_YEAR = 2024;
const YEARS = [...Array(new Date().getFullYear() - START_YEAR + 1)];

const CURRENT_MONTH = `0${new Date().getMonth() + 1}`.slice(-2);
const CURRENT_YEAR = new Date().getFullYear().toString();

function Summary() {
	const {t} = useTranslation();
	const {merchant} = useAuthStore();
	const [sort, setSort] = useState({field: 'id', isAscending: false});
	const [page, setPage] = useState(1);
	const [showFilter, setShowFilter] = useState(false);
	const [filter, setFilter] = useState({status: '', test: '', supplier: null})
	const [date, setDate] = useState({month: CURRENT_MONTH, year: CURRENT_YEAR});

	const tableHeader = [
		{column: 'date', title: 'Date', sortable: false},
		{column: 'received_amount', title: 'Received Amount', sortable: false},
		{column: 'total_count', title: 'Total', sortable: false},
		{column: 'pending_count', title: 'Pending', sortable: false},
		{column: 'completed_count', title: 'Completed', sortable: false},
		{column: 'failed_count', title: 'Failed', sortable: false},
		{column: 'expired_count', title: 'Expired', sortable: false},
		{column: 'refunded_count', title: 'Refunded', sortable: false},
		{column: 'canceled_count', title: 'Canceled', sortable: false},
		{column: 'on_hold_count', title: 'On Hold', sortable: false},
	]
	const handleChangeSort = (field) => {
		if (sort.field === field) {
			setSort(prevState => ({...prevState, isAscending: !prevState.isAscending}));
		} else {
			setSort({field: field, isAscending: true});
		}
	};
	const fetchPaymentSummary = useQuery(
		['payment_summary', {date, filter}],
		async () => {
			const {data} = await apollo.query({
				query: GET_PAYMENT_SUMMARY_LIST,
				variables: {
					month: date?.month || CURRENT_MONTH,
					year: date?.year || CURRENT_YEAR,
					supplier: filter.supplier,
				},
			});
			return data?.getPaymentSummaryList;
		},
		{keepPreviousData: false},
	);

    const fetchMerchantSuppliersList = useQuery({
        queryKey: 'merchant_suppliers_list',
        queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_MERCHANT_SUPPLIERS_LIST,
                variables: {},
            });
            return data?.getMerchantSuppliersList;
        },
        keepPreviousData: false
    });

	const records = fetchPaymentSummary?.isSuccess ? fetchPaymentSummary : null;
    const suppliersList = fetchMerchantSuppliersList?.isSuccess ? fetchMerchantSuppliersList?.data : null;

	return (
		<DashboardLayout>
			<PageHeader title="Summary" description="" className="mb-4">
				<div className="mt-3 grid grid-cols-3 gap-3">
					<SelectInput name="supplier-filter" label="Supplier" id="supplier-filter" get={filter.supplier}
								  set={v => setFilter({...filter, supplier: v})}>
						<option value=""></option>
						{suppliersList?.map(supplier => <option key={supplier.supplierUuid} value={supplier.supplierUuid}>{supplier.supplierName}</option>)}
					</SelectInput>
					<SelectInput name="month-filter" label="Month" id="month-filter" get={date.month} set={v => setDate({...date, month: v})}>
						<option value="01">January</option>
						<option value="02">February</option>
						<option value="03">March</option>
						<option value="04">April</option>
						<option value="05">May</option>
						<option value="06">June</option>
						<option value="07">July</option>
						<option value="08">August</option>
						<option value="09">September</option>
						<option value="10">October</option>
						<option value="11">November</option>
						<option value="12">December</option>
					</SelectInput>
					<SelectInput name="year-filter" label="Year" id="year-filter" get={date.year} set={v => setDate({...date, year: v})}>
						{YEARS.map((_, i) => <option key={i} value={START_YEAR + i}>{START_YEAR + i}</option>)}
					</SelectInput>
				</div>
			</PageHeader>
			{fetchPaymentSummary.isLoading && <Loading/>}
			
			{(fetchPaymentSummary.isSuccess && records) && <>
				{/*<Pagination paginator={records?.paginatorInfo} get={page} set={setPage}/>*/}
				<div className="flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<table className="min-w-full divide-y divide-gray-300">
								<thead>
									<tr>
										{tableHeader?.map(item => (<th key={item.column} scope="col" className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
											{!item.hidden && <>
												{item.sortable ? <button onClick={() => handleChangeSort(item.column)} type="button" className="group inline-flex">
													{item.title}
													<SortIcon sort={sort} field={item.column}/>
												</button> : <span>{item.title}</span>}
											</>}
										</th>))}
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200">
									{records?.data?.map((record) => (<tr key={record?.date} className="hover:bg-gray-200/60">
										<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{record?.date}</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatNumberFloat(record?.receivedAmount)} {merchant?.currency || ''}</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatNumberInt(record?.totalCount)}</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatNumberInt(record?.pendingCount)}</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatNumberInt(record?.completedCount)}</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatNumberInt(record?.failedCount)}</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatNumberInt(record?.expiredCount)}</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatNumberInt(record?.refundedCount)}</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatNumberInt(record?.canceledCount)}</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatNumberInt(record?.onHoldCount)}</td>
										{/*"date": "2024-04-24",*/}
										{/* "pendingCount": 12,*/}
										{/* "completedCount": 10,*/}
										{/* "failedCount": 12,*/}
										{/* "expiredCount": 8,*/}
										{/* "refundedCount": 15,*/}
										{/* "canceledCount": 13,*/}
										{/* "totalCount": 13,*/}
										{/* "receivedAmount": 11773.5,*/}
									</tr>))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				{/*<Pagination paginator={records?.paginatorInfo} get={page} set={setPage}/>*/}
			</>}
		
		</DashboardLayout>
	);
}

export default Summary;

