import React, {useEffect, useRef, useMemo} from 'react';
import AuthLayout from "../../layouts/AuthLayout";
import ButtonLong from "../../components/ButtonLong";
import TextInput from "../../components/TextInput";
import useAuthStore from "../../store/auth";
import {Link, useNavigate} from "react-router-dom";
import StepPanel from "../../components/registration/StepPanel";
import {useTranslation} from "react-i18next";
import useRegistrationFormStore from "../../store/registration-form";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../components/Button";
import {useMutation, useQuery} from "react-query";
import {apollo} from "../../utils/apollo";
import {GET_REGISTRATION_COUNTRIES, GET_REGISTRATION_FORM_FIELDS, REGISTER} from "../../utils/graphql";
import RegistrationStringField from "../../components/registration/fields/RegistrationStringField";
import RegistrationFileField from "../../components/registration/fields/RegistrationFileField";
import RegistrationCountryField from "../../components/registration/fields/RegistrationCountryField";
import RegistrationBooleanField from "../../components/registration/fields/RegistrationBooleanField";
import Loading from "../../components/Loading";
import SelectInput from "../../components/SelectInput";
import {cn} from "../../utils/style";
import {toast} from "react-toastify";

function Register() {
    const {t} = useTranslation();
    const {
        step, setStep, credentials, setCredentials, dynamicFormValue, setDynamicFormValue,
        getCountry
    } = useRegistrationFormStore()
    const recaptcha = useRef()
    const navigate = useNavigate();
    const {token} = useAuthStore()

    const fetchCountries = useQuery({
        queryKey: 'registration_countries',
        queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_REGISTRATION_COUNTRIES,
                variables: {},
            });
            return data?.getRegistrationCountries;
        }
    });

    const fetchRegistrationFormFields = useQuery({
        queryKey: 'registration_form_fields',
        queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_REGISTRATION_FORM_FIELDS,
                variables: {
                    country: getCountry()
                },
            });
            return data?.getRegistrationForm;
        },
        enabled: !!(getCountry() && step === 1),
    });

    const fields = useMemo(() => {
        return fetchRegistrationFormFields?.data || null;
    }, [fetchRegistrationFormFields]);

    useEffect(() => {
        if (token) navigate('/dashboard');
    }, [navigate, token]);

    const registerMutation = useMutation({
        mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: REGISTER,
                variables: {
                    input: {
                        ...credentials,
                        country: getCountry(),
                        fields: JSON.stringify(dynamicFormValue)
                    }
                },
            });
            return data?.register;
        },
        onError: ((error) => {
            if (error.graphQLErrors) {
                error.graphQLErrors.map((item) => console.log(item));
            }
        }),
        onSuccess: async (data) => {
            if (data) {
                toast.success('Registration has been submitted successfully, You will get an email soon.');
                setTimeout(() => {
                    window.location.reload();
                }, 5000)
            } else {
                toast.error('Something went wrong!')
            }
        }
    })

    const submitRegistrationForm = async (e) => {
        e.preventDefault();
        const captcha = recaptcha.current?.getValue();
        if (!captcha) {
            alert('Please verify the reCAPTCHA!');
            return;
        }

        registerMutation.mutate();
    }

    return (
        <AuthLayout title={t('auth.register_page_title')}>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-7xl">
                <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">

                    {(step > 0 && fetchRegistrationFormFields.isSuccess && !!fields?.length) && <div className="mt-6">
                        <StepPanel step={step} onClick={value => setStep(value)}/>
                    </div>}

                    {(step > 0 && fetchRegistrationFormFields.isSuccess && !fields?.length) &&
                        <div className="mx-auto max-w-sm">
                            <p className="pb-3">We're truly sorry, but an error has occurred.</p>
                            <p className="pb-3">Kindly try again later, and if the error continues, please reach out to
                                our support team.</p>
                        </div>}

                    {(fetchRegistrationFormFields.isLoading || fetchCountries.isLoading) && <Loading/>}

                    {fetchCountries?.data && step === 0 && <div className="mx-auto max-w-sm">
                        <SelectInput name="country" label={t('auth.registration_country')} id="country"
                                     get={dynamicFormValue['country'] || ''}
                                     set={v => setDynamicFormValue('country', v)}>
                            <option value=""></option>
                            {fetchCountries?.data?.map((country) => (
                                <option key={country?.id} value={country?.id}>{country?.englishName}</option>))}
                        </SelectInput>
                        <Button className="text-lg w-full max-w-sm mt-6" onClick={() => {
                            if (getCountry()) setStep(step + 1)
                        }}>{t('auth.continue')}</Button>
                    </div>}

                    {!!fields?.length && <div className="mt-6">
                        <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:pb-0">
                            {fields?.map(field => {
                                if (field.fieldType === 'string') return <RegistrationStringField key={field.id}
                                                                                                  field={field}
                                                                                                  show={step === field.displayOnSection}
                                                                                                  get={dynamicFormValue}
                                                                                                  set={setDynamicFormValue}/>
                                if (field.fieldType === 'file') return <RegistrationFileField key={field.id}
                                                                                              field={field}
                                                                                              show={step === field.displayOnSection}
                                                                                              get={dynamicFormValue}
                                                                                              set={setDynamicFormValue}/>
                                if (field.fieldType === 'country') return <RegistrationCountryField key={field.id}
                                                                                                    field={field}
                                                                                                    show={step === field.displayOnSection}
                                                                                                    get={getCountry()}
                                                                                                    set={null}
                                                                                                    readOnly={true}
                                                                                                    countries={fetchCountries?.data}/>
                                // if (field.fieldType === 'country') return <RegistrationStringField key={field.id} field={field} show={step === field.displayOnSection} get={dynamicFormValue} set={setDynamicFormValue} defaultValue={registrationCountry} readOnly={true}/>
                                if (field.fieldType === 'boolean') return <RegistrationBooleanField key={field.id}
                                                                                                    field={field}
                                                                                                    show={step === field.displayOnSection}
                                                                                                    get={dynamicFormValue}
                                                                                                    set={setDynamicFormValue}/>
                                return null
                            })}
                        </div>

                        <form className={cn("space-y-6 max-w-[480px] mx-auto", {
                            'hidden': step !== 4
                        })} action="#" method="POST">
                            <TextInput label="Name" get={credentials.name} set={v => setCredentials('name', v)}
                                       id="name" name="name" type="text" required={true}/>
                            <TextInput label="Email address" get={credentials.email}
                                       set={v => setCredentials('email', v)} id="email" name="email" type="email"
                                       required={true}/>
                            <TextInput label="Password" get={credentials.password}
                                       set={v => setCredentials('password', v)} id="password" name="password"
                                       type="password" required={true}/>
                            <TextInput label="Confirm Password" get={credentials.confirm_password}
                                       set={v => setCredentials('confirm_password', v)} id="confirm_password"
                                       name="confirm_password" type="password" required={true}/>

                            <div className="flex">
                                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY}/>
                            </div>
                            <div className={cn({'hidden': step !== 4})}>
                                <ButtonLong type="button" onClick={submitRegistrationForm}>Sign up</ButtonLong>
                            </div>
                        </form>

                        <div className={cn("items-center sm:gap-4 sm:py-4", {
                            'flex': step < 4,
                            'hidden': step >= 4
                        })}>
                            <Button className="text-lg w-full max-w-sm"
                                    onClick={() => setStep(step + 1)}>Continue</Button>
                        </div>
                    </div>}
                </div>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Already signed up?{' '}
                    <Link to="/login" className="font-semibold leading-6 text-blue-500 hover:text-blue-500">
                        Go ahead and log in.
                    </Link>
                </p>
            </div>
        </AuthLayout>
    );
}


export default Register;
