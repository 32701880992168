import React from 'react';

function SelectInput({children, label, id, name, get, set, disabled, className}) {
	return (
		<div className={className}>
			<label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
				{label}
			</label>
			<div className="mt-2">
				<select
					id={id}
					name={name}
					disabled={disabled}
					className="disabled:opacity-60 disabled:bg-gray-200 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-500 sm:text-sm sm:leading-6"
					value={get || ''}
					onChange={e => set(e.target.value)}
				>
					{children}
				</select>
			</div>
		</div>
	);
}

export default SelectInput;