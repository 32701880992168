import React, {useMemo} from 'react';
import DashboardLayout from "../../layouts/DashboardLayout";
import {ArrowDownIcon, ArrowUpIcon} from '@heroicons/react/20/solid'
import {CreditCardIcon, DocumentDuplicateIcon, UsersIcon} from '@heroicons/react/24/outline'
import {clsx} from "clsx";
import {apollo} from "../../utils/apollo";
import {useQuery} from "react-query";
import {GET_MERCHANT_STATISTICS, GET_MERCHANT_SUPPLIERS_STATISTICS} from "../../utils/graphql";
import {formatNumberFloat, formatNumberInt} from "../../utils/helpers";
import Loading from "../../components/Loading";

// const stats = [
// 	{id: 1, name: 'Number of transactions', stat: '71,897', icon: DocumentDuplicateIcon, change: '122', changeType: 'increase'},
// 	{id: 2, name: 'Successful payments', stat: '58.16%', icon: CreditCardIcon, change: '5.4%', changeType: 'increase'},
// 	{id: 3, name: 'New customers', stat: '34', icon: UsersIcon, change: '12', changeType: 'decrease'},
// ]
const icons = {
    DocumentDuplicateIcon,
    CreditCardIcon,
    UsersIcon
};

function Dashboard() {

    const fetchMerchantStatistics = useQuery({
        queryKey: 'dashboard_statistics',
        queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_MERCHANT_STATISTICS,
                variables: {},
            });
            return data?.getMerchantStatistics;
        },
        keepPreviousData: false
    });

    const fetchMerchantSuppliersStatistics = useQuery({
        queryKey: 'dashboard_suppliers_statistics',
        queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_MERCHANT_SUPPLIERS_STATISTICS,
                variables: {},
            });
            return data?.getMerchantSuppliersStatistics;
        },
        keepPreviousData: false
    });

    const stats = fetchMerchantStatistics?.isSuccess ? fetchMerchantStatistics?.data : null;
    const suppliersStats = fetchMerchantSuppliersStatistics?.isSuccess ? fetchMerchantSuppliersStatistics?.data : null;

    return (
        <DashboardLayout>
            <div>
                {/*<h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3>*/}
                {fetchMerchantStatistics.isLoading && <Loading/>}

                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
                    {stats?.map((item) => {
                        let IconComponent = icons[item.icon] || null;
                        return <div
                            key={item.id}
                            className="relative overflow-hidden rounded-lg bg-white px-4 py-6 shadow sm:px-6 sm:pt-6"
                        >
                            <dt>
                                <div className="absolute rounded-md bg-gradient-to-br from-green-500 to-blue-500 p-3">
                                    {IconComponent &&
                                        <IconComponent className="h-6 w-6 text-white" aria-hidden="true"/>}
                                </div>
                                <p className="ltr:ml-16 rtl:mr-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
                            </dt>
                            <dd className="ltr:ml-16 rtl:mr-16 flex items-baseline">
                                <p className="text-2xl font-semibold text-gray-900">{prepareStatsValue(item)}</p>
                                {(item.change && item.changeType) && <p
                                    className={clsx(
                                        item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                                        'ml-2 flex items-baseline text-sm font-semibold'
                                    )}
                                >
                                    {item.changeType === 'increase' ? (
                                        <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                                                     aria-hidden="true"/>
                                    ) : (
                                        <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                                                       aria-hidden="true"/>
                                    )}

                                    <span
                                        className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                                    {item.change}
                                </p>}
                            </dd>
                        </div>
                    })}
                </dl>

                {!!suppliersStats?.length && <div className="grid grid-cols-1 mt-8 gap-3">
                    <div className="xl:border-2 col-span-2 xl:col-span-1 rounded-lg">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                    <tr>
                                        <th scope="col"
                                            className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                            <span>Supplier Uuid</span>
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                            <span>Supplier Name</span>
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                            <span>Available Amount</span>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {suppliersStats?.map((record) => (
                                        <tr key={record?.id} className="hover:bg-gray-200/60">
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.id}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.name}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.value}</td>
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/*<div>*/}
                    {/*    /!*other stats*!/*/}
                    {/*</div>*/}
                </div>}

            </div>
        </DashboardLayout>
    );
}


function prepareStatsValue(item) {
    let value = item.stat;
    if (item.valueType === 'float') value = formatNumberFloat(value);
    else if (item.valueType === 'integer') value = formatNumberInt(value);
    return `${item?.prefix ? item?.prefix + ' ' : ''}${value}${item?.suffix ? ' ' + item?.suffix : ''}`;
}

export default Dashboard;

