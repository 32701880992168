import React from 'react';
import {clsx} from "clsx";
import {cn} from "../../../utils/style";

function RegistrationStringField({field, show, get, set, defaultValue, readOnly}) {
    return (
        <div className={cn("sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4", {
            'sm:grid': show,
            'hidden': !show,
        })}>
            <label htmlFor={field.fieldName}
                   className="flex flex-col text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                <div>
                    {field.displayName} <span className="text-red-500/50 text-[10px]"
                                              aria-label="Required">{field.required ? '(Required)' : ''}</span>
                </div>
                {field.format && <div className="text-xs text-gray-500">
                    Format: {field.format}
                </div>}
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
                <input
                    type="text"
                    name={field.fieldName}
                    id={field.fieldName}
                    autoComplete="given-name"
                    value={get[field.fieldName] || defaultValue || ''}
                    readOnly={readOnly || false}
                    onChange={e => !!set && set(field.fieldName, e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:max-w-xs sm:text-sm sm:leading-6"
                />
            </div>
        </div>
    );
}

export default RegistrationStringField;