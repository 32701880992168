import {create} from 'zustand'
import {createJSONStorage, persist} from "zustand/middleware";
// import {createJSONStorage} from "zustand/middleware";

const useAuthStore = create(persist((set) => ({
    token: null,
    user: null,
    roles: null,
    permissions: null,
    merchant: null,
    testMode: null,

    setImpersonateToken: (value) => set({
        token: value,
        user: null,
        roles: null,
        permissions: null,
        merchant: null,
        testMode: null,
    }),

    clearToken: () => set({
        token: null,
        user: null,
        roles: null,
        permissions: null,
        merchant: null,
        testMode: null,
    }),
    setToken: (value) => set({token: value, user: null}),
    setUserAuth: (value) => set({...value}),
    setUser: (value) => set({user: value}),
    setRoles: (value) => set({roles: value}),
    setPermissions: (value) => set({permissions: value}),
    setMerchant: (value) => set({merchant: value}),
    setTestMode: (value) => set({testMode: value}),
}), {
    name: 'x-auth', // name of item in the storage (must be unique)
    storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used, sessionStorage
    partialize: (state) => ({
        token: state.token,
        user: state.user,
        roles: state.roles,
        permissions: state.permissions,
        merchant: state.merchant,
    }),
}))

export default useAuthStore;