import React, {useMemo, useState} from 'react';
import PageHeader from "../../../components/PageHeader";
import DashboardLayout from "../../../layouts/DashboardLayout";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import {GET_MERCHANT_SUPPLIERS_LIST, GET_SUPPLIER_LIST} from "../../../utils/graphql";
import {apollo} from "../../../utils/apollo";
import Loading from "../../../components/Loading";
import SortIcon from "../../../components/SortIcon";
import StatusBadge from "../../../components/StatusBadge";
import {Link} from "react-router-dom";
import PaginationFull from "../../../components/PaginationFull";
import ButtonInternalLink from "../../../components/ButtonInternalLink";

function SubVendors() {
    const {t} = useTranslation();
    const [sort, setSort] = useState({field: 'id', isAscending: false});
    const [page, setPage] = useState(1);
    const [showFilter, setShowFilter] = useState(false);
    const tableHeader = [
        // {column: 'user_uuid', title: t('user_management.table.user_uuid'), sortable: true},
        {column: 'supplier_uuid', title: t('sub_vendors.table.supplierUuid'), sortable: false},
        {column: 'supplier_name', title: t('sub_vendors.table.supplierName'), sortable: false},
        {column: 'business_name', title: t('sub_vendors.table.businessName'), sortable: false},
        {column: 'email', title: t('sub_vendors.table.email'), sortable: false},
        {column: 'phone', title: t('sub_vendors.table.phone'), sortable: false},
        {column: 'active', title: t('sub_vendors.table.active'), sortable: false},
        {column: 'display_supplier_details', title: t('sub_vendors.table.displaySupplierDetails'), sortable: false},
    ]

    const fetchSupplierList = useQuery(
        ['users_list', {page, sort}],
        async () => {
            const {data} = await apollo.query({
                query: GET_SUPPLIER_LIST,
                variables: {
                    sortBy: sort.field?.toUpperCase(),
                    sortDirection: sort.isAscending ? "ASC" : "DESC",
                    page,
                    first: 15,
                    filter: {}
                },
            });
            return data?.getSupplierList;
        },
        {keepPreviousData: false}
    );
    const records = useMemo(() => fetchSupplierList?.isSuccess ? fetchSupplierList?.data : null, [fetchSupplierList]);

    const handleChangeSort = (field) => {
        if (sort.field === field) {
            setSort(prevState => ({...prevState, isAscending: !prevState.isAscending}));
        } else {
            setSort({field: field, isAscending: true});
        }
    };

    return (
        <DashboardLayout>
            <PageHeader title={t('sub_vendors.page_title')} description={t('sub_vendors.page_description')}>
                {/*<div className="flex items-center gap-3">*/}
                {/*    <ButtonInternalLink*/}
                {/*        href="/merchant/sub-vendors/create">{t('sub_vendors.create_sub_vendor')}</ButtonInternalLink>*/}
                {/*</div>*/}
            </PageHeader>
            {showFilter && <div className="mt-3 shadow-md bg-white border rounded px-4 py-2">
                filter
            </div>}
            {fetchSupplierList.isLoading && <Loading/>}
            {(fetchSupplierList.isSuccess && records) && <>
                <div className="flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                <tr>
                                    {tableHeader?.map(item => (<th key={item.column} scope="col"
                                                                   className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
                                        {!item.hidden && <>
                                            {item.sortable ?
                                                <button onClick={() => handleChangeSort(item.column)} type="button"
                                                        className="group inline-flex">
                                                    {item.title}
                                                    <SortIcon sort={sort} field={item.column}/>
                                                </button> : <span>{item.title}</span>}
                                        </>}
                                    </th>))}
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {records?.data?.map((record) => (
                                    <tr key={record?.supplierUuid} className="hover:bg-gray-200/60">
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{record?.supplierUuid}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.supplierName}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.businessName}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.email}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.phone}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><StatusBadge
                                            status={record?.active ? 'Yes' : 'No'}/></td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><StatusBadge
                                            status={record?.displaySupplierDetails ? 'Yes' : 'No'}/></td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0">
                                            <Link to={`/merchant/sub-vendors/view/${record?.supplierUuid}`}
                                                  className="text-blue-500 hover:text-blue-900">View</Link>
                                        </td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/*<Pagination paginator={records?.paginatorInfo} get={page} set={setPage}/>*/}
                <PaginationFull paginator={records?.paginatorInfo} get={page} set={setPage}/>
            </>}
        </DashboardLayout>
    );
}

export default SubVendors;