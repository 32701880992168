import React from "react";
import Home from "./pages/home";
import Register from "./pages/auth/register";
import Login from "./pages/auth/login";
import ResetPassword from "./pages/auth/reset-password";
import Dashboard from "./pages/account/dashboard";
import Setting from "./pages/account/setting";
import Invoice from "./pages/account/invoice";
import Payment from "./pages/account/payment/payment";
import Subscription from "./pages/account/subscription";
import Logout from "./pages/auth/logout";
import Profile from "./pages/account/profile";
import PaymentCreate from "./pages/account/payment/payment-create";
import Settlement from "./pages/account/settlement/settlement";
import PaymentGateway from "./pages/merchant/payment-gateway/payment-gateway";
import UserManagement from "./pages/merchant/sub-user/sub-user";
import PaymentView from "./pages/account/payment/payment-view";
import PaymentGatewayView from "./pages/merchant/payment-gateway/payment-gateway-view";
import PaymentGatewayCreate from "./pages/merchant/payment-gateway/payment-gateway-create";
import UserManagementCreate from "./pages/merchant/sub-user/sub-user-create";
import UserManagementView from "./pages/merchant/sub-user/sub-user-view";
import Registration from "./pages/account/registration";
import Notification from "./pages/account/notification";
import Pay from "./pages/pay";
import SettlementView from "./pages/account/settlement/settlement-view";
import SettlementCreate from "./pages/account/settlement/settlement-create";
import Summary from "./pages/account/summary";
import DocsV1Index from "./pages/docs/v1/docs_v1_index";
import ConditionsOfUse from "./pages/conditions_of_use";
import Impersonate from "./pages/impersonate";
import PaymentCreateBulk from "./pages/account/payment/payment-create-bulk";
import UpdatePassword from "./pages/auth/update-password";
import NotFound from "./pages/not_found";
import ConditionsOfUseSatim from "./pages/conditions_of_use_satim";
import SubVendors from "./pages/merchant/sub-vendor/sub-vendors";
import SubVendorView from "./pages/merchant/sub-vendor/sub-vendor-view";
import SubVendorCreate from "./pages/merchant/sub-vendor/sub-vendor-create";
import Refund from "./pages/account/refund/refund";

/**
 * @typedef {Object} Route
 * @property {string} path - The route path
 * @property {React.Component} element - The React component to be rendered
 */
const routes = [
    {path: "/", element: <Home/>},
    {path: "/login", element: <Login/>},
    {path: "/logout", element: <Logout/>},
    {path: "/register", element: <Register/>},
    {path: "/update-password/:token", element: <UpdatePassword/>},
    {path: "/reset-password", element: <ResetPassword/>},
    {path: "/dashboard", element: <Dashboard/>},
    {path: "/summary", element: <Summary/>},
    {path: "/notifications", element: <Notification/>},
    {path: "/merchant/settings", element: <Setting/>},
    {path: "/merchant/invoices", element: <Invoice/>},

    {path: "/merchant/payments", element: <Payment/>},
    {path: "/merchant/payments/create", element: <PaymentCreate/>},
    {path: "/merchant/payments/create-bulk", element: <PaymentCreateBulk/>},
    {path: "/merchant/payments/view/:payment_uuid", element: <PaymentView/>},

    {path: "/merchant/subscriptions", element: <Subscription/>},

    {path: "/merchant/settlements", element: <Settlement/>},
    {path: "/merchant/settlements/create", element: <SettlementCreate/>},
    {path: "/merchant/settlements/view/:settlement_uuid", element: <SettlementView/>},

    {path: "/merchant/payment-gateway", element: <PaymentGateway/>},
    {path: "/merchant/payment-gateway/create", element: <PaymentGatewayCreate/>},
    {path: "/merchant/payment-gateway/view/:id", element: <PaymentGatewayView/>},

    {path: "/merchant/sub-users", element: <UserManagement/>},
    {path: "/merchant/sub-users/create", element: <UserManagementCreate/>},
    {path: "/merchant/sub-users/view/:id", element: <UserManagementView/>},

    {path: "/merchant/sub-vendors", element: <SubVendors/>},
    {path: "/merchant/sub-vendors/view/:supplier_uuid", element: <SubVendorView/>},
    {path: "/merchant/sub-vendors/create", element: <SubVendorCreate/>},

    {path: "/merchant/refunds", element: <Refund/>},

    {path: "/merchant/registration", element: <Registration/>},
    {path: "/profile", element: <Profile/>},

    {path: "/merchant/docs/v1", element: <DocsV1Index/>},

    {path: "/pay/:uuid", element: <Pay/>},
    {path: "/impersonate/:token", element: <Impersonate/>},
    {path: "/conditions-of-use", element: <ConditionsOfUse/>},
    {path: "/conditions-of-use-satim", element: <ConditionsOfUseSatim/>},
    {
        path: "*",
        element: <NotFound/>,
    },
];

export default routes;