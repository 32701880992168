import React from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";

function SubVendorCreate() {
    return (
		<DashboardLayout>

        </DashboardLayout>
    );
}

export default SubVendorCreate;