import React from 'react';
import {Link} from "react-router-dom";
import {clsx} from "clsx";

function ButtonInternalLink({children, href, secondary, hidden}) {
	if (hidden) return null;
	return (
		<Link
			to={href}
			className={clsx(
				secondary
					? 'from-red-500 to-orange-500 hover:from-red-400 hover:to-orange-400'
					: 'from-green-500 to-blue-500 hover:from-green-400 hover:to-blue-400',
				"block rounded-md bg-gradient-to-br px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500")}
		>
			{children}
		</Link>
	);
}

export default ButtonInternalLink;