import React, {useMemo} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {useParams} from 'react-router-dom';
import {useMutation, useQuery} from "react-query";
import StatusBadge from "../../../components/StatusBadge";
import Loading from "../../../components/Loading";
import {apollo} from "../../../utils/apollo";
import {GET_SETTLEMENT, GET_SETTLEMENT_REPORT_URL} from "../../../utils/graphql";
import Button from "../../../components/Button";
import InformationLine from "../../../components/InformationLine";
import MoneyHighlight from "../../../components/MoneyHighlight";
import {useTranslation} from "react-i18next";

function SettlementView() {
    const {settlement_uuid} = useParams();
    const {t} = useTranslation();

    const getSettlementReportUrlMutation = useMutation({
        mutationFn: async (variables) => {
            const {data} = await apollo.mutate({
                mutation: GET_SETTLEMENT_REPORT_URL,
                variables: {
                    settlementUuid: variables?.settlementUuid
                },
            });
            if (!!data?.getSettlementReportUrl) {
                window.location.href = data?.getSettlementReportUrl;
            }
        }
    })
    const fetchSettlement = useQuery(
        ['settlement', {settlement_uuid}],
        async () => {
            const {data} = await apollo.query({
                query: GET_SETTLEMENT,
                variables: {
                    settlement_uuid,
                },
            });
            return data?.getSettlement;
        },
        {keepPreviousData: false}
    );
    const settlement = useMemo(() => fetchSettlement?.isSuccess ? fetchSettlement?.data : null, [fetchSettlement]);

    return (
        <DashboardLayout>
            <PageHeader back="/merchant/settlements" title="Settlement View"
                        description="This page provides you with the capability to generate new payment links for seamless transactions."/>

            {fetchSettlement.isLoading && <Loading/>}
            {(fetchSettlement.isSuccess && settlement) && <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <InformationLine title="Payment Uuid" value={settlement?.settlementUuid}/>
                    <InformationLine title="Merchant" value={settlement?.merchant?.merchantName}/>
                    <InformationLine title="Supplier" value={settlement?.supplier?.supplierName}/>
                    <InformationLine title="User" value={settlement?.user?.name}/>
                    <InformationLine title="Authority" value={settlement?.authority?.name}/>
                    <InformationLine title="Request Amount" value={<MoneyHighlight value={settlement?.requestAmount}
                                                                                   currency={settlement?.currency?.symbol}/>}/>
                    <InformationLine title="Settlement Amount" value={!!settlement?.settlementAmount
                        ? <MoneyHighlight value={settlement?.settlementAmount} currency={settlement?.currency?.symbol}
                                          className="!text-2xl"/>
                        : 'N/A'
                    }/>
                    <InformationLine title="Settlement Date" value={settlement?.settlementDate}/>
                    <InformationLine title="Status" value={<StatusBadge status={settlement?.status}/>}/>
                    <InformationLine title="Created At" value={settlement?.createdAt}/>
                    <InformationLine title="Payment Reference" value={settlement?.paymentReference}/>
                    <InformationLine title="Remarks" value={settlement?.remarks}/>
                    <InformationLine title="Receipt" value={<Button onClick={() => {
                        window.location.href = `${process.env.REACT_APP_PAYD_API_BASE_URL}/${settlement?.receipt}`;
                    }} disabled={!settlement?.receipt}>
                        {t('settlement.download')}
                    </Button>}/>
                    <InformationLine title="Report" value={
                        <Button onClick={() => {
                            getSettlementReportUrlMutation.mutate({settlementUuid: settlement?.settlementUuid})
                        }} disabled={getSettlementReportUrlMutation.isLoading}>
                            {t('settlement.download')}
                        </Button>}/>
                </dl>
            </div>}

        </DashboardLayout>
    );
}

export default SettlementView;



